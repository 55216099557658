import React, { Component } from "react"
import { Image } from "react-bootstrap"
import { bgBlog } from '../../../../images';
import { BackgroundImages } from "../../../../shared";
import { OutboundLink } from 'gatsby-plugin-gtag'

class HeaderBlog extends Component {
  render() {
    return (
      <div className="header-blog">
        <BackgroundImages style={{
          backgroundImage: `url(${bgBlog})`,
          zIndex: -1
        }}>
          <div className="box-header">
            <Image alt="900x500" data-src={require("../../../../images/jpg/blog-desktop.jpg")} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=" className="image-header-blog hidden-mobi lazyload" />
            <Image alt="900x500" data-src={require("../../../../images/jpg/blog-mobi.jpg")} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=" className="image-header-blog hidden-desktop lazyload" />
            <div className="box-text-header-blog">
              <p className="text-header-blog">
                Why do we need redis-graphql-subcription?
              </p>
              <p className="description-header-blog">
                Redis-graphql-subscription is a library implement redis associate with graphql-subscription. This package implements the PubSubEngine Interface from the graphql-subscriptions package and also the new AsyncIterator interface. It allows you to connect your subscriptions manager to a Redis Pub Sub mechanism to support multiple subscription manager instances.
              </p>

              <OutboundLink href="/contact-us" className="read-more">Read More</OutboundLink>
            </div>
          </div>
        </BackgroundImages>
      </div>
    )
  }
}

export default HeaderBlog;
