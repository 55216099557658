import React, {Component} from "react"
import Layout from "../../../../components/layout"
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import HeaderBlog from "../../../../components/screens/Blog/HeaderBlog"
import { Helmet } from "react-helmet"
import { OutboundLink } from 'gatsby-plugin-gtag'

class BlogPage extends Component {
  render() {
    const { data } = this.props;
    const { currentPage, numPages } = this.props.pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage = currentPage - 1 === 1 ? '/' : (currentPage - 1).toString()
    const nextPage = (currentPage + 1).toString()
    return (
      <Layout pageInfo={{ pageName: "Blog" }} sitePage="site-page">
        <Helmet defer={false}>
            <title>Blog</title>
            <meta name="description" content="We share our knowledge and experience on our blog every week" />
        </Helmet>
        <HeaderBlog />
        <br/>
        <h1 className="title-blog">Our blog where we share our knowledge and experience</h1>
        <ul className="wrapper-blog">
          {data.allStrapiBlog.edges.map(document => (
            <li key={document.node.id} className="box-blog">
              <OutboundLink href={`/${document.node.url}`}>
                <Img fixed={document.node.image.childImageSharp.fixed} alt="900x500" className="image-item-blog lazyload"/>
              </OutboundLink>
              <h2>
                <OutboundLink href={`/${document.node.url}`}>{document.node.title}</OutboundLink>
              </h2>
              <div className="bottom-box-blog">
                <p>{document.node.updated_at}</p>
                <p>{document.node.author.username}</p>
              </div>
            </li>
          ))}

          <div className="pagination">
            {!isFirst && (
              <OutboundLink href={`blog/${prevPage}`} rel="prev" className="arrow-pagination">
                ←
              </OutboundLink>
            )}
            {Array.from({ length: numPages }, (_, i) => (
              <li
                key={`pagination-number${i + 1}`}
              >
                <OutboundLink
                  href={`/blog/${i === 0 ? '' : i + 1}`}
                  className="number-pagination"
                  style={{
                    textDecoration: 'none',
                    color: i + 1 === currentPage ? '#ffffff' : '',
                    background: i + 1 === currentPage ? '#f7ab3b' : '',
                  }}
                >
                  {i + 1}
                </OutboundLink>
              </li>
            ))}
            {!isLast && (
              <OutboundLink href={`blog/${nextPage}`} rel="next" className="arrow-pagination">
                →
              </OutboundLink>
            )}
          </div>
        </ul>
      </Layout>
    )
}
}

export default BlogPage

export const pageQuery = graphql`
  query BlogQuery($skip: Int, $limit: Int) {
    allStrapiBlog(
      sort: { order: DESC, fields: [updated_at] }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          id
          image {
            childImageSharp {
              fixed(height: 235) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          title
          content
          url
          updated_at(formatString: "MMMM Do YYYY")
          author {
            id
            username
          }
        }
      }
    }
  }
`
